import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/PhotoCapture.css';

function PhotoCapture() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const handlePhotoCapture = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    const userName = localStorage.getItem('userName') || 'Nieznany Użytkownik';  // Pobieramy nazwę użytkownika z localStorage
    
    // Debugowanie: Sprawdź, czy userName jest poprawnie pobrane
    console.log('User Name:', userName);

    const timestamp = new Date().toISOString().slice(0, 16).replace('T', '_').replace(/:/g, '');
    const customFileName = `Od_${userName}_${timestamp}.${file.name.split('.').pop()}`;


    formData.append('file', file, customFileName);
    formData.append('description', description);
    formData.append('userName', userName);  // Przesyłamy userName na backend

    setIsUploading(true);

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / total);
          setUploadProgress(progress);
        },
      });

      if (response.status === 200) {
        navigate('/gallery');
      } else {
        alert(`Nie udało się przesłać pliku. Serwer zwrócił: ${response.statusText}`);

      }
    } catch (error) {
      console.error('Błąd podczas przesyłania pliku:', error);
      alert('Wystąpił błąd podczas przesyłania pliku.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="photo-capture-container">
      <h2>Zrób Zdjęcie</h2>
      <textarea
        placeholder="Dodaj opis do zdjęcia (opcjonalnie)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
        id="photo-input"
      />
      <label htmlFor="photo-input" className="photo-capture-input">
        Wybierz Zdjęcie
      </label>

      {isUploading && (
        <div className="upload-progress">
          Postęp przesyłania: {uploadProgress}%
        </div>
      )}
    </div>
  );
}

export default PhotoCapture;