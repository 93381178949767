import React, { useState, useEffect } from 'react';
import '../styles/Home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500); // Skrócone opóźnienie ładowania

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (path) => {
        setLoading(true);
        setTimeout(() => {
            navigate(path);
        }, 1500); // Skrócone opóźnienie nawigacji
    };

    return (
        <div className="home-container">
            {loading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <div className="home-header">
                        <h1>Witamy na naszej stronie</h1>
                        <p>Wybierz jedną z opcji poniżej, aby kontynuować.</p>
                    </div>
                    <div className="options-grid">
                        <div className="option-card" onClick={() => handleOptionClick('/photo-capture')}>
                            <div className="option-content">
                                <h2>Zrób zdjęcie</h2>
                                <p>Prześlij zdjęcia, aby podzielić się wspomnieniami.</p>
                            </div>
                        </div>
                        <div className="option-card" onClick={() => handleOptionClick('/video-capture')}>
                            <div className="option-content">
                                <h2>Zrób film</h2>
                                <p>Prześlij filmy, aby uwiecznić wyjątkowe momenty.</p>
                            </div>
                        </div>
                        <div className="option-card" onClick={() => handleOptionClick('/gallery')}>
                            <div className="option-content">
                                <h2>Galeria</h2>
                                <p>Zobacz wszystkie przesłane zdjęcia i filmy.</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;
