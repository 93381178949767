import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StartPage from './components/StartPage';
import Home from './components/Home';
import PhotoCapture from './components/PhotoCapture';
import VideoCapture from './components/VideoCapture';
import Gallery from './components/Gallery';
import PhotoDetail from './components/PhotoDetail'; 
import NotFound from './components/NotFound'; // Import NotFound

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/startpage" element={<StartPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/photo-capture" element={<PhotoCapture />} />
        <Route path="/video-capture" element={<VideoCapture />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:fileId" element={<PhotoDetail />} />
        <Route path="*" element={<NotFound />} /> {/* Użycie komponentu NotFound */}
      </Routes>
    </Router>
  );
}

export default App;
