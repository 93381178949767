import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/VideoCapture.css';

function VideoCapture() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [description, setDescription] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const navigate = useNavigate();

  const isMediaRecorderSupported = !!window.MediaRecorder;
  const isMobileDevice = /Mobi|Android|iPhone/i.test(navigator.userAgent);

  useEffect(() => {
    // Czyszczenie zasobów przy demontażu komponentu
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      const stream = videoRef.current && videoRef.current.srcObject;
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const handleStartRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Twoja przeglądarka nie obsługuje nagrywania wideo.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      setIsRecording(true);

      // Odczekaj jedną pętlę zdarzeń, aby React mógł zaktualizować DOM
      await new Promise(resolve => setTimeout(resolve, 0));

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        console.error('videoRef.current jest null');
      }

      // Ustawienia nagrywania z preferowanym typem MIME
      let options = { mimeType: 'video/mp4;codecs="avc1.42E01E, mp4a.40.2"' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        options = { mimeType: 'video/webm;codecs=vp8,opus' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options = { mimeType: '' }; // Pozwól przeglądarce wybrać domyślny typ MIME
        }
      }

      console.log('Używany typ MIME:', options.mimeType);

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = function (e) {
        if (e.data && e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = function () {
        const mimeType = mediaRecorderRef.current.mimeType || 'video/webm';
        const blob = new Blob(chunksRef.current, { type: mimeType });
        setVideoBlob(blob);

        // Zatrzymaj strumień kamery
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
          videoRef.current.srcObject = null;
        }
        setIsRecording(false);
        setIsPaused(false);
      };

      mediaRecorder.start();
    } catch (err) {
      console.error('Błąd dostępu do urządzeń:', err);
      alert(`Nie można uzyskać dostępu do kamery i mikrofonu. Błąd: ${err.message}`);
      setIsRecording(false);
    }
  };

  const handlePauseRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  };

  const handleRetakeRecording = () => {
    // Resetuj stan nagrania
    setVideoBlob(null);
    setVideoFile(null);
    chunksRef.current = [];
  };

  // Funkcja dla input file
  const handleVideoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleUploadVideo = async () => {
    const fileToUpload = videoBlob || videoFile;
    if (!fileToUpload) {
      alert('Nie ma wideo do przesłania.');
      return;
    }

    const formData = new FormData();
    const userName = localStorage.getItem('userName') || 'Nieznany Użytkownik';
    const timestamp = new Date().toISOString().slice(0, 16).replace('T', '_').replace(/:/g, '');
    const fileExtension = fileToUpload.name ? fileToUpload.name.split('.').pop() : 'webm';
    const customFileName = `Od_${userName}_${timestamp}.${fileExtension}`;

    formData.append('file', fileToUpload, customFileName);
    formData.append('description', description);
    formData.append('userName', userName);

    setIsUploading(true);
    const startTime = new Date().getTime();

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / total);
          setUploadProgress(progress);

          const currentTime = new Date().getTime();
          const timeElapsed = (currentTime - startTime) / 1000;
          const speed = (loaded / timeElapsed / 1024).toFixed(2);
          setUploadSpeed(speed);
        },
      });

      if (response.status === 200) {
        navigate('/gallery');
      } else {
        alert(`Nie udało się przesłać pliku. Serwer zwrócił: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Błąd podczas przesyłania pliku:', error);
      alert('Wystąpił błąd podczas przesyłania pliku.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="video-capture-container">
      <h2>Nagraj Wideo</h2>
      <textarea
        placeholder="Dodaj opis do wideo (opcjonalnie)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="video-description-input"
      />

      {isMediaRecorderSupported && !isMobileDevice ? (
        <>
          <video
            ref={videoRef}
            autoPlay
            muted
            style={{ width: '100%', display: isRecording ? 'block' : 'none' }}
          />

          {(!videoBlob && !isRecording) && (
            <button onClick={handleStartRecording}>
              Rozpocznij nagrywanie
            </button>
          )}

          {isRecording && (
            <>
              <div className="recording-buttons">
                {!isPaused ? (
                  <button onClick={handlePauseRecording}>
                    Pauzuj
                  </button>
                ) : (
                  <button onClick={handleResumeRecording}>
                    Wznów
                  </button>
                )}
                <button onClick={handleStopRecording}>
                  Zatrzymaj
                </button>
              </div>
            </>
          )}

          {videoBlob && !isRecording && (
            <>
              <video
                src={URL.createObjectURL(videoBlob)}
                controls
                style={{ width: '100%', marginTop: '20px' }}
              />
              <div className="action-buttons">
                <button onClick={handleUploadVideo}>
                  Wyślij wideo
                </button>
                <button onClick={handleRetakeRecording}>
                  Nagraj ponownie
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <p>Użyj przycisku poniżej, aby nagrać wideo za pomocą aplikacji kamery, a następnie prześlij je.</p>
          {!videoFile && (
            <>
              <input
                type="file"
                accept="video/*"
                capture
                onChange={handleVideoSelect}
                style={{ display: 'none' }}
                id="video-input"
              />
              <label htmlFor="video-input" className="video-capture-input">
                Nagraj lub wybierz wideo
              </label>
            </>
          )}
          {videoFile && (
            <>
              <video
                src={URL.createObjectURL(videoFile)}
                controls
                style={{ width: '100%', marginTop: '20px' }}
              />
              <div className="action-buttons">
                <button onClick={handleUploadVideo}>
                  Wyślij wideo
                </button>
                <button onClick={handleRetakeRecording}>
                  Wybierz ponownie
                </button>
              </div>
            </>
          )}
        </>
      )}

      {isUploading && (
        <div className="upload-progress">
          <div
            className="upload-progress-bar"
            style={{ width: `${uploadProgress}%` }}
          >
            {uploadProgress}%
          </div>
          <div className="upload-speed">
            Prędkość przesyłania: {uploadSpeed} KB/s
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoCapture;
