import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LazyLoad from 'react-lazyload';
import '../styles/Gallery.css';
import { saveAs } from 'file-saver';

function Gallery() {
  const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [selectedItem, setSelectedItem] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [isCommented, setIsCommented] = useState(false);
  const commentsRef = useRef(null);

  const handleDownloadClick = async () => {
    if (!selectedItem || !selectedItem._id || !selectedItem.fileName) {
      console.error("Brak danych do pobrania pliku");
      return;
    }

    try {
      const response = await axios.get(`/api/get-presigned-url/${selectedItem._id}`);

      const presignedUrl = response.data.url;

      const fileResponse = await fetch(presignedUrl);
      const blob = await fileResponse.blob();

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = selectedItem.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      } else {
        saveAs(blob, selectedItem.fileName);
      }
    } catch (error) {
      console.error("Błąd przy pobieraniu pliku:", error);
      alert("Wystąpił błąd podczas pobierania pliku.");
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get('/api/files');

        setFiles(response.data);
      } catch (error) {
        console.error('Błąd pobierania plików:', error);
      }
    };
    fetchFiles();
  }, [activeTab]);

  const fetchLikesAndComments = async (fileId) => {
    try {
      const userName = localStorage.getItem('userName') || 'Nieznany Użytkownik';
      const likeResponse = await axios.get(`/api/likes/${fileId}`, { params: { userName } });
      setLikeCount(likeResponse.data.count);
      setIsLiked(likeResponse.data.isLikedByUser);

      const commentResponse = await axios.get(`/api/comments/${fileId}`);
      setComments(commentResponse.data);
    } catch (error) {
      console.error('Błąd pobierania polubień i komentarzy:', error);
    }
  };

  const handleSelectItem = (file) => {
    setSelectedItem(file);
    fetchLikesAndComments(file._id);
    setIsCommentsVisible(false);
  };

  const handleLike = async () => {
    const userName = localStorage.getItem('userName') || 'Nieznany Użytkownik';

    try {
      if (isLiked) {
        await axios.post('/api/unlike', { userName, fileId: selectedItem._id });
        setLikeCount(likeCount - 1);
      } else {
        await axios.post('/api/like', { userName, fileId: selectedItem._id });
        setLikeCount(likeCount + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Błąd przy polubieniu:', error);
    }
  };

  const handleAddComment = async () => {
    const userName = localStorage.getItem('userName') || 'Nieznany Użytkownik';

    if (newComment.trim()) {
      const newCommentData = {
        text: newComment,
        fileId: selectedItem._id,
        userName: userName,
      };
      try {
        await axios.post('/api/comment', newCommentData);
        setComments([...comments, newCommentData]);
        setNewComment('');
      } catch (error) {
        console.error('Błąd przy dodawaniu komentarza:', error);
      }
    }
  };

  const handleShowMoreComments = () => {
    setIsCommentsExpanded(!isCommentsExpanded);
    setShowAllComments(!showAllComments);
  };

  const handleCommentClick = () => {
    setIsCommented(!isCommented);
    setIsCommentsVisible(!isCommentsVisible);
  };

  const closeShare = () => {
    setShowShareOptions(false);
  };

  const handleZoomToggle = () => {
    setZoomed(!zoomed);
  };

  const filteredFiles = files.filter((file) => {
    if (activeTab === 'photos') {
      return file.fileName.endsWith('.jpg') || file.fileName.endsWith('.jpeg') ||
        file.fileName.endsWith('.png') || file.fileName.endsWith('.gif') ||
        file.fileName.endsWith('.bmp') || file.fileName.endsWith('.webp') ||
        file.fileName.endsWith('.tiff') || file.fileName.endsWith('.svg');
    }
    if (activeTab === 'videos') {
      return file.fileName.endsWith('.mp4') || file.fileName.endsWith('.mov') ||
        file.fileName.endsWith('.avi') || file.fileName.endsWith('.mkv') ||
        file.fileName.endsWith('.webm') || file.fileName.endsWith('.flv') || 
        file.fileName.endsWith('.wmv');
    }
    return true;
  });

  return (
    <div className="gallery-container">
      <div className="gallery-header">
        <div className={`tab ${activeTab === 'photos' ? 'active' : ''}`} onClick={() => setActiveTab('photos')}>
          Zdjęcia
        </div>
        <div className={`tab ${activeTab === 'videos' ? 'active' : ''}`} onClick={() => setActiveTab('videos')}>
          Wideo
        </div>
        <div className={`tab ${activeTab === 'all' ? 'active' : ''}`} onClick={() => setActiveTab('all')}>
          Całość
        </div>
      </div>

      <div className="gallery-grid">
        {filteredFiles.length > 0 ? (
          filteredFiles.map((file) => (
            <LazyLoad key={file._id} height={200} offset={100} once>
              <div className="gallery-item" onClick={() => handleSelectItem(file)}>
                {file.fileName.endsWith('.jpg') || file.fileName.endsWith('.png') ? (
                  <img src={file.url} alt={file.fileName} />
                ) : (
                  <video src={file.url} controls muted={false} />
                )}
                <div className="user-name-overlay">{file.userName}</div>
              </div>
            </LazyLoad>
          ))
        ) : (
          <p>Brak plików do wyświetlenia.</p>
        )}
      </div>

      {selectedItem && (
        <div className="detail-view-overlay" onClick={() => setSelectedItem(null)}>
          <div className={`detail-view ${zoomed ? 'zoomed' : ''}`} onClick={(e) => e.stopPropagation()}>
            {selectedItem.fileName.endsWith('.jpg') || selectedItem.fileName.endsWith('.png') ? (
              <img
                src={selectedItem.url}
                alt={selectedItem.fileName}
                className="zoomable"
                onClick={handleZoomToggle}
              />
            ) : (
              <video src={selectedItem.url} controls muted={false} autoPlay={false} />
            )}

            <div className="description-container">
              <strong className="user-name">{selectedItem.userName}</strong>: {selectedItem.description}
              <div className="interaction-icons">
                <div className={`like-icon ${isLiked ? 'liked' : ''}`} onClick={handleLike} role="button" tabIndex="0">
                  <svg aria-label="Lubię to!" fill="currentColor" height="24" role="img" viewBox="0 0 52 52" width="24">
                    <title>Lubię to!</title>
                    {isLiked ? (
                      <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                    ) : (
                      <path
                        d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      ></path>
                    )}
                  </svg>
                </div>

                <div className={`comment-icon ${isCommented ? 'commented' : ''}`} onClick={handleCommentClick} role="button" tabIndex="0">
                  <svg aria-label="Skomentuj" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24">
                    <title>Skomentuj</title>
                    <path
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
                      fill="none"
                      stroke="currentColor"
                    ></path>
                  </svg>
                </div>

                <div className="share-icon" onClick={handleDownloadClick} role="button" tabIndex="0">
                  <svg viewBox="0 0 24 24" aria-hidden="true" className="share-icon">
                    <g>
                      <path d="M12 2.59l5.7 5.7-1.41 1.42L13 6.41V16h-2V6.41l-3.3 3.3-1.41-1.42L12 2.59zM21 15l-.02 3.51c0 1.38-1.12 2.49-2.5 2.49H5.5C4.11 21 3 19.88 3 18.5V15h2v3.5c0 .28.22.5.5.5h12.98c.28 0 .5-.22.5-.5L19 15h2z"></path>
                    </g>
                  </svg>
                </div>
              </div>

              <section className="like-section">
                <div className="like-container">
                  <div className="like-inner">
                    <span className="like-text">
                      <span>
                        Liczba polubień: <span className="like-count">{likeCount}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </section>

              {isCommentsVisible && (
                <div className={`comment-section ${isCommentsVisible ? 'expanded' : ''}`} ref={commentsRef}>
                  <h4>Komentarze:</h4>
                  {comments.slice(0, showAllComments ? comments.length : 3).map((comment, index) => (
                    <div key={index} className="comment">
                      <strong>{comment.userName}:</strong> {comment.text}
                    </div>
                  ))}
                  {comments.length > 3 && (
                    <button className="show-comments-btn" onClick={handleShowMoreComments}>
                      {isCommentsExpanded ? 'Zwiń komentarze' : 'Pokaż więcej komentarzy'}
                    </button>
                  )}

                  <textarea
                    placeholder="Dodaj komentarz"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                  />
                  <button className="add-comment-btn" onClick={handleAddComment}>
                    Dodaj Komentarz
                  </button>
                </div>
              )}
            </div>

            <FontAwesomeIcon
              icon={faTimes}
              className="svg-inline--fa fa-xmark animated-close-icon close-btn"
              onClick={() => setSelectedItem(null)}
            />
          </div>

          {showShareOptions && (
            <div className="share-container" onClick={(e) => e.stopPropagation()}>
              <FontAwesomeIcon icon={faTimes} className="svg-inline--fa fa-xmark animated-close-icon close-share" onClick={closeShare} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Gallery;
