import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/StartPage.css';



const StartPage = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.trim()) {
      localStorage.setItem('userName', name.trim());  // Zapisz nazwę użytkownika do localStorage
      navigate('/home');
    } else {
      alert('Proszę wpisać imię');
    }
  };

  return (
    <div className="startpage-container">
      <div className="startpage-background">
        {/* Lazy loading for background images */}
        <picture>
          <source type="image/webp" media="(max-width: 600px)" srcSet="/assets/images/wedding-mobile.webp" />
          <source type="image/webp" media="(min-width: 601px)" srcSet="/assets/images/wedding-desktop.webp" />
          <source media="(max-width: 600px)" srcSet="/assets/images/wedding-mobile.jpeg" />
          <source media="(min-width: 601px)" srcSet="/assets/images/wedding-desktop.jpeg" />
        </picture>
      </div>
      <div className="startpage-content">
        <h1>Emilia i Michał</h1>
        <p>Dodaj zdjęcia i wideo z naszego wesela!</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Imię</label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Wpisz swoje imię"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <button type="submit" className="btn-submit">
            Wyślij
          </button>
        </form>
      </div>
    </div>
  );
};

export default StartPage;
