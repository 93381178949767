import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PhotoDetail = () => {
  const { fileId } = useParams(); // Pobranie fileId z URL
  console.log('fileId:', fileId); // Dodaj logi

  useEffect(() => {
    const fetchFile = async () => {
      if (!fileId) {
        console.error('fileId jest undefined');
        return;
      }

      try {
        const response = await axios.get(`http://3.75.171.8:5001/files/${fileId}`);
        console.log('File data:', response.data);
        // Ustawienie danych pliku w stanie, itp.
      } catch (error) {
        console.error('Błąd przy pobieraniu pliku:', error);
      }
    };

    fetchFile();
  }, [fileId]); // Refetch, gdy fileId się zmienia

  return (
    <div>
      {/* Zawartość komponentu */}
    </div>
  );
};

export default PhotoDetail;
